ul#custom_fields {
  padding-left: 0;
  margin-left: 0;

  .card_custom_field {
    margin: 0 0 10px 0;
  }

  li {
    list-style: none;
    text-decoration: none;

    .custom_field_type {
      margin-top: -1em;
      float: left;
      border: 1px solid black;
      border-bottom: 1px solid white;
      padding: .1em;
    }

    .custom_field_body {
      padding-top: 16px;
      border: 1px solid #e3e3e3;
      background-color: #f5f5f5;
      border-radius: 5px;

      label.checkbox {
        // Wide labels make for accidental clickage
        width: 30%;
      }

      .filter-heading {
        margin-top: 40px;

        .left-side {
          text-align: right;
          line-height: 40px;
        }

        .right-side {
          line-height: 40px;
        }
      }

      .filter-option {
        margin: 5px 5px;

        .left-side {
          text-align: right;
          line-height: 40px;
        }
      }

      .dep-attrs {
        .fa.fa-minus-sign {
          color: $red;
        }

        .fa.fa-plus-circle {
          color: $green;
        }

        .row {
          margin-bottom: 8px;
        }

        &.remove-highlighted {
          /* When remove button is highlighting, the box gets this class */
          border-color: $red;
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(red, 20%);
          @include transition(border linear .2s, box-shadow linear .2s);
        }

        &.template, &.to-destroy {
          display: none;
        }
      }
    }
  }

  .actions-dropdown-form-editor{
    position:absolute;
    right:10px;
  }
}

.form-editor-type{
  max-width: 70%;
}

.page-name-custom-form-section{
  margin-left:17px
}


